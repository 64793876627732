/* eslint react-hooks/rules-of-hooks: 0 */

import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Spacer from "../components/Spacer"
import Seo from "../components/Seo"
import Button from "../components/Button"
import { breakpoints } from "../assets/globalStyles"
import FlexibleContent from "../components/FlexibleContent"
import BlogPreview from "../components/BlogPreview"
import BackgroundSplodge from "./../assets/images/header-splodge.svg"

const StyledHeader = styled.div`
  background-size: contain;
  background-position: bottom right;
  background-color: #d42b69;
  background-image: url(${BackgroundSplodge});
  background-repeat: no-repeat;
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: ${breakpoints.md}px) {
      align-items: flex-start;
    }

    h1 {
      color: #ffffff;
      font-family: Poppins;
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      text-align: center;
      @media screen and (min-width: ${breakpoints.md}px) {
        text-align: left;
      }
      @media screen and (min-width: ${breakpoints.xl}px) {
        font-size: 72px;
        line-height: 84px;
      }
    }

    h2 {
      color: #ffffff;
      font-family: Poppins;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      font-weight: 400;
      @media screen and (min-width: ${breakpoints.md}px) {
        text-align: left;
      }
      @media screen and (min-width: ${breakpoints.xl}px) {
        line-height: 36px;
      }
    }
  }

  .header-image {
    max-width: 300px;
    margin: 0 auto;
    @media screen and (min-width: ${breakpoints.md}px) {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
    }
    @media screen and (min-width: ${breakpoints.lg}px) {
      max-width: 100%;
    }
  }
`

export default function index() {
  const {
    wpPage: {
      homepageContentFields: {
        header: {
          title,
          subtitle,
          button: {
            label,
            link: { url: buttonUrl },
          },
          image: {
            localFile: {
              childImageSharp: { gatsbyImageData },
            },
          },
        },
      },
      seo,
      featuredImage,
      flexibleGeneralContentFields: { content: pageContent },
    },
  } = useStaticQuery(
    graphql`
      {
        wpPage(isFrontPage: { eq: true }) {
          title
          homepageContentFields {
            header {
              title
              subtitle
              button {
                label
                link {
                  url
                }
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          seo {
            metaDesc
            title
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
          flexibleGeneralContentFields {
            ...FlexibleContent
          }
        }
      }
    `
  )

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <StyledHeader>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="header-content">
                <Spacer
                  size={0}
                  when={{
                    md: 40,
                    lg: 80,
                  }}
                />
                <h1>{title}</h1>
                <Spacer size={24} />
                <h2>{subtitle}</h2>
                <Spacer size={40} />
                <Button
                  colour="secondary"
                  as={Link}
                  to={buttonUrl}
                  variant="contained"
                >
                  {label}
                </Button>
                <Spacer size={40} when={{ md: 80, lg: 120 }} />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="header-image">
                <GatsbyImage
                  image={gatsbyImageData}
                  alt="Header Image"
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </StyledHeader>
      <Spacer size={32} when={{ lg: 48 }} />
      <FlexibleContent content={pageContent} />
      <Spacer size={32} when={{ md: 48, lg: 80 }} />
      <BlogPreview />
    </>
  )
}
